<template>
  <main role="main" class="d-flex flex-column" style="min-height:100vh">

    <div style="flex:1 1 auto">

      <!-- Navigation -->
      <page-header nav="3"></page-header>
      <!-- Navigation -->

      <div v-show="true" class="container-fluid p-2 p-md-3 p-xl-4">
        <!-- PLANS -->
          <!--TODO: 
          * Make FREE VERY GOOD - TRY USAGE CUTOFFS (instead of FEATURE CUTOFFS)
          * VISUALIZER/PROFESSIONAL - FREE for Numerical Cloud USers (I wont be able to sell)
          * PROFESSIONAL/ENTERPRISE - API access (include levels for usage)
          * Free cuts off after 50 uploads/month, 25 API requests/month (only data extraction - no matrix)
          * Visualizer ($29/39): 1000 uploads/month, 500 API calls/month
          * Professional ($149/199): 5000 uploads/month, 2500 API calls/month
          * Enterprise ($299/399): 5000 uploads/month, 10000 API calls/month
          * Fee of $0.03 USD per API call
        -->
        <div class="d-none d-lg-block">
            <h5 class="modal-title "><i class="fas fa-fw fa-pencil-ruler mr-1 text-info"></i>PCB Preflight Plans</h5>
            <p class="text-secondary">Easy to Use. Low Cost. Secure</p>
        </div>

        <!-- PLAN CARDS -->
        <div class="pricing card-deck flex-column flex-md-row mb-1">

          <!--STARTER
            Enhance the Free Features (but leave minor annoyances):
            * No need to limit Jobs
            * Dim/Hide advanced DFM rules
            * Storage: Jobs remains for 15 days
            * PCB Preflight (desktop software)
            <li title="Edit/Save DFM Rules"><i class="fas fa-fw fa-check text-success mr-1"></i>Edit/Save <b>DFM Rules</b></li>
            <li title="Add Comments and Markup"><i class="fas fa-fw fa-check text-success mr-1"></i>Add <b>Comments and Markup</b></li>
          -->
          <div class="card card-pricing text-center shadow-sm mb-4">
            <span class="h6 w-60 mx-auto px-4 py-1 rounded-bottom bg-primary text-white shadow-sm">Starter</span>
            <div class="bg-transparent card-header pt-4 border-0">
              <h3 class="h3 font-weight-normal text-primary text-center mb-0"><span class="price">Free</span></h3>
              <span class="h6 font-weight-normal text-secondary text-center text-sm mb-0">Forever</span>
            </div>
            <hr />
            <div class="card-body pt-0 px-3">
              <ul class="list-unstyled mb-2 text-left">
            <li class="text-center" title="Leading cloud-based Design and Visualization solution.  Easy to use and available 24/7"><b>Online PCB Analysis + Collaborate</b></li>
            <li title="Upload files: Gerber, ODB++, IPC-2581, NC Drill/Rout, DXF, Compressed files (ZIP,TGZ,RAR), FAB3000 (WRK) and more."><i class="fas fa-fw fa-check text-success mr-1"></i><b>Upload</b> Gerber, ODB++, IPC-2581, NC Drill-Rout, DXF and more.</li>
            <li title="Extract PCB fabrication and assembly data used for RFQ/Sales purposes"><i class="fas fa-fw fa-check text-success mr-1"></i><b>Extracts PCB</b> Manufacturing data</li>
            	<li title="Run DFM Checks and Highlight Violations"><i class="fas fa-fw fa-check text-success mr-1"></i><b>Free DFM Checks</b> and Highlight Violations</li>
            <li title="Collaborate and Share"><i class="fas fa-fw fa-check text-success mr-1"></i><b>Collaborate</b> and <b>Share</b> your DFM design results</li>
            <li title=""><i class="fas fa-fw fa-check text-success mr-1"></i>Create <b>3</b> Preflight Jobs*</li>
            <li title="Free API Sandbox access" v-b-popover.hover.top="'Easily test and evaluate the PCB Preflight API on all Sandbox files.  Perform up to Level 2 API Matrix Features including: Export (Images and Reports), Advanced DFM Checks, SMT Stencil Checks, Netlist Compare and PCB Sales/RFQ Tools.'"><i class="fas fa-fw fa-check text-success mr-1"></i><b>Free API Sandbox</b> access*</li>
            	<li title="Get Free Desktop Viewer"><i class="fas fa-fw fa-check text-success mr-1"></i><b>Free Desktop Viewer</b> for Windows and Linux </li>
              </ul>
            </div>
            <div class="card-footer">
              <router-link tag="a" :to="{name:'Signup'}"><b-button class="btn" variant="primary">Sign Up</b-button></router-link>
            </div>
          </div>

          <!--VISUALIZER
            This level will be included with Numerical Cloud:
            Proposed Paid Features:
        * Promote Additional Security, Privacy benefits.
        * PCB Preflight AI
        * Incognito mode (scrub elements of the design: Nets, Components, or just a small section).
            * Add Folder (on dashboard)
            * Save SVG updates
            * Show BOM/Layer Stackup panes
            * Unlimited Features
            * Advanced DFM Checks
            * Netlist Extract?
          
          -->
          <div class="card card-pricing shadow-sm text-center mb-4">
            <span class="h6 w-60 mx-auto px-4 py-1 rounded-bottom bg-primary text-white shadow-sm">Visualizer</span>
            <div class="bg-transparent card-header pt-4 border-0">
              <h3 class="h3 font-weight-normal text-primary text-center mb-1">$33/month</h3>
              <span class="h6 font-weight-normal text-secondary text-center text-sm mb-0">Billed annually or $49 month-to-month</span>
            </div>
            <hr />
            <div class="card-body pt-0 px-3">
              <ul class="list-unstyled mb-2 text-left">
                <li class="text-center" ><b>Everything in Starter, plus...</b></li>
                <li title="Invite unlimited people to collaborate, view, comment, and inspect designs on PCBPreflight.com"><i class="fas fa-fw fa-check text-success mr-1"></i><b>Unlimited Jobs</b> and Collaboration</li>
                <li title="Get advanced DFM checks: Acid traps, Starved Thermal, Power/Ground Short, Solder Bridges"><i class="fas fa-fw fa-check text-success mr-1"></i><b>Advanced</b> DFM Checks</li>
                <li title="Get PCB Assembly checks: Verify Component clearances, rotation, hieght. BOM File Comparison, Footprint to Pad Registration, Invalid Refdes, and more"><i class="fas fa-fw fa-check text-success mr-1"></i><b>Assembly</b> DFM Checks</li>
                <li title="Verify component solderpaste aspect and area ratios, clearances, paste distribution, and more"><i class="fas fa-fw fa-check text-success mr-1"></i><b>SMT Stencil</b> DFM Checks</li>
                <li title="Verify the electrical connectivity of your circuit design"><i class="fas fa-fw fa-check text-success mr-1"></i><b>Find Net Shorts</b> and Opens</li>
                <li title="Temporarily invite any guests or students (i.e non-members) for team collaboration."><i class="fas fa-fw fa-check text-success mr-1"></i><b>Invite Guests</b> and Students</li>
                <li title="Quickly prepare a manufacturing quotation from your PCB design."><i class="fas fa-fw fa-check text-success mr-1"></i><b>PCB Pricing</b> and Sales Tools</li>
                <li title="API Features: Optionally gain access to all API Matrix features (up to Level 2)" v-b-popover.hover.top="'Export (Images and Reports), Advanced DFM Checks, SMT Stencil Checks, Netlist Compare and PCB Sales/RFQ Tools.  API usage is optional: $0.03 per API call'"><i class="fas fa-fw fa-check text-success mr-1"></i><b>API Features (Level 2)**</b><br/>API usage is optional: <b>$0.05 per API call</b></li>
              </ul>
            </div>
            <div class="card-footer">
              <router-link tag="a" :to="{name:'Signup'}"><b-button class="btn" variant="primary">Sign Up</b-button></router-link>
            </div>
          </div>


          <!--PROFESSIONAL-->
          <div class="card card-pricing shadow-sm text-center mb-4">
            <span class="h6 w-60 mx-auto px-4 py-1 rounded-bottom bg-primary text-white shadow-sm">Professional</span>
            <div class="bg-transparent card-header pt-4 border-0">
              <h3 class="h3 font-weight-normal text-primary text-center mb-1">$129/month</h3>
              <span class="h6 font-weight-normal text-secondary text-center text-sm mb-0">Billed annually or $149 month-to-month</span>
            </div>
            <hr />
            <div class="card-body pt-0 px-3">
              <ul class="list-unstyled mb-2 text-left">
                <li class="text-center"><b>Everything in Visualizer, plus...</b></li>     
                <li title="Export popular EDA, CAD and 3D file formats."><i class="fas fa-fw fa-check text-success mr-1"></i><b>Export CAM files</b> Gerber, Drill/Rout, ODB++, IPC-2581, DXF, Netlist, BOM, X/Y Centroid and more</li>
                <li title=""><i class="fas fa-fw fa-check text-success mr-1"></i><b>Online CAM Tools</b> PCB Panelization, Copper Pour, Teardrops, Etch factors, SMT Stencil tools and more</li>
                <li title="Create Fixed Size and optimized panels - Saves money on manufacturing."><i class="fas fa-fw fa-check text-success"></i><b>PCB Panelization</b> Create Fixed Size and optimized panels</li>
                <li title=""><i class="fas fa-fw fa-check text-success"></i><b>SMT Stencil tools</b> </li>
                <li title="API Features: Optionally gain access to all API Matrix features" v-b-popover.hover.top="'Export (Gerber, NC Drill/Mill, DXF, ODB++, IPC-2581, High-Res images, SVG), Editing tools (Add/Transform), Object Selection and Filters. Plus PCB Panelization, SMT Stencil tools, PCB Fabrication and Assembly tools. API usage is optional: $0.03 per API call'"><i class="fas fa-fw fa-check text-success mr-1"></i><b>API Features (All Levels)**</b><br/>API usage is optional: $0.03 per API call. <b>Saves 40% per call vs Visualizer plan</b></li>
              </ul>

            </div>
            <div class="card-footer">
              <router-link tag="a" :to="{name:'Signup'}"><b-button class="btn" variant="primary">Sign Up</b-button></router-link>
            </div>
          </div>

          <!--ENTERPRISE--> 
          <div class="card card-pricing shadow-sm text-center mb-4">
            <span class="h6 w-60 mx-auto px-4 py-1 rounded-bottom bg-primary text-white shadow-sm">Enterprise</span>
            <div class="bg-transparent card-header pt-4 border-0">
          <h3 class="h3 font-weight-normal text-primary text-center mb-1">$299+ /month</h3>
          <span class="h6 font-weight-normal text-secondary text-center text-sm mb-0">Unlimited API calls starting at $299 month.</span>
            </div>
            <hr />
            <div class="card-body pt-0 px-3">
              <ul class="list-unstyled mb-2 text-left">
                <li class="text-center"><b>Everything in Professional, plus...</b></li>     
                <li title="Enterprise Plans start at $500/month"><i class="fas fa-fw fa-check text-success mr-1"></i><b>Unlimited API calls</b> at a cost-effective monthly rate**</li>           
                <li title="API Features: All Levels " v-b-popover.hover.top="'Perform Editing (Add/Transform), Object Selection and Filters, PCB Panelization, SMT Stencil tools, PCB Fabrication and Assembly tools.'"><i class="fas fa-fw fa-check text-success mr-1"></i><b>API Features: All Levels</b></li>
                <li title="AWS GovCloud (US) is the leading regulated industry cloud solution that technology leaders have trusted to manage sensitive data and controlled unclassified information (CUI).  More cost-effective and easier to manage than in-house hosting."><i class="fas fa-fw fa-check text-secondary mr-1"></i><b>AWS GovCloud</b> US hosting for maximum Security and Compliance (ITAR, ISO, etc). Additional Cost Option***</li>           
                <li title="In-house hosting is perfect for organizations that require strict data security and control."><i class="fas fa-fw fa-check text-secondary mr-1"></i><b>In-House</b> hosting (server-based). Perfect for strict data security requirements and control. Additional Cost Option****</li>   
              </ul>
            </div>
            <div class="card-footer">
              <router-link tag="a" :to="{name:'Signup'}"><b-button class="btn" variant="primary">Sign Up</b-button></router-link>
            </div>
          </div>

        </div>
        <!-- END PLAN CARDS -->

        <!-- COMPARE FEATURES -->
        <div class="card mb-5">
          <div class="card-header">
            <h3 class="mb-3 text-center">Compare Features<span class="h6 text-muted ml-2"> Review all differences between the PCB Preflight Plans</span></h3>
          </div>
          <div class="card-body" style="padding:0">
            <table class="table table-hover mt-2">
              <thead>
                <tr>
                  <th style="border-top:none" scope="col"></th>
                  <th style="text-align:center;border-top:none" scope="col">Starter<br/><span class="text-muted text-sm-center ml-2">Free</span></th>
                  <th style="text-align:center;border-top:none" scope="col">Visualizer<br/><span class="text-muted text-sm-center ml-2">$33/mo</span></th>
                  <th v-show="true" style="text-align:center;border-top:none" scope="col">Professional<br/><span class="text-muted text-sm-center ml-2">$129/mo</span></th>
                  <th style="text-align: center;border-top:none" scope="col">Enterprise<br/><span class="text-muted text-sm-center ml-2">Book a Demo</span></th>
                </tr>
              </thead>
              <tbody>

                <!-- FEATURES -->
                <tr>
                  <th scope="row" class="text-md-left text-primary bg-light">FEATURES</th>
                  <td class="text-md-left text-primary bg-light"> </td>
                  <td class="text-md-left text-primary bg-light"> </td>
                  <td class="text-md-left text-primary bg-light"> </td>
                  <td class="text-md-left text-primary bg-light"> </td>
                </tr>
                <tr>
                  <th scope="row" text-muted title="Online editor supports several different analysis and viewing modes."><i class="fas fa-fw fa-caret-right"></i>PCB Preflight Editor (PCB Analysis + Collaborate)</th>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                </tr>
                <tr>
                  <th scope="row" title=""><i class="fas fa-fw fa-caret-right"></i>Upload files: Gerber, NC Drill/Rout, ODB++, IPC-2581(DMP), DXF, FAB3000 (*.wrk) and more.</th>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                </tr>         
                <tr>
                  <th scope="row" title=""><i class="fas fa-fw fa-caret-right"></i>Upload Compressed Files: ZIP, TGZ, RAR, TAR</th>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                </tr>            
                <tr>
                  <th scope="row" title=""><i class="fas fa-fw fa-caret-right"></i>Cross platform: Works on Mac, Windows, Linux, and iOS/Android devices.</th>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                </tr>           
                <tr>
                  <th scope="row" title=""><i class="fas fa-fw fa-caret-right"></i>Output PCB Documents: PDF, TXT, JPG and Excel files</th>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                </tr>
                <tr>
                  <th scope="row" title=""><i class="fas fa-fw fa-caret-right"></i>Output FAB3000 Workspace (*.wrk) <br/><a style="font-size:smaller;" href="https://www.numericalinnovations.com/collections/fab-3000-gerber-cam" target="_blank" rel="noopener noreferrer"><i class="fa fa-fw fa-info-circle mr-1"></i> FAB3000 is an industry-leading PCB/CAM tool (for Windows and Linux)</a></th>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                </tr>

                <tr>
                  <th scope="row" title=""><i class="fas fa-fw fa-caret-right"></i>PCB Preflight AI (beta)</th>
                  <td style="text-align: center"> </td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                </tr>
                <tr>
                  <th scope="row" title="Optimize your job workflow by creating multiple folders to separate groups, companies and colleagues."><i class="fas fa-fw fa-caret-right"></i>Create Job Folders</th>
                  <td style="text-align: center"> </td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                </tr>

                <!-- Collaboration -->
                <tr>
                  <th scope="row" class="text-md-left text-primary bg-light">COLLABORATION</th>
                  <td class="text-md-left text-primary bg-light"> </td>
                  <td class="text-md-left text-primary bg-light"> </td>
                  <td class="text-md-left text-primary bg-light"> </td>
                  <td class="text-md-left text-primary bg-light"> </td>
                </tr>
                <tr>
                  <th scope="row" title=""><i class="fas fa-fw fa-caret-right"></i>Meet up with Unlimited Collaborators</th>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                </tr>     
                <tr>
                  <th scope="row" title=""><i class="fas fa-fw fa-caret-right"></i>Add 'Real-time' Commenting and Notes</th>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                </tr> 
                <tr>
                  <th scope="row" title=""><i class="fas fa-fw fa-caret-right"></i>Add Markup</th>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                </tr>                                                                                                     
                <tr>
                  <th scope="row" title=""><i class="fas fa-fw fa-caret-right"></i>Job Sharing Permissions</th>
                  <td style="text-align: center"> </td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                </tr> 
                <tr>
                  <th scope="row" title=""><i class="fas fa-fw fa-caret-right"></i>Shareable links: Control who gets access to files.</th>
                  <td style="text-align: center"> </td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                </tr>                    
                <tr>
                  <th scope="row" title=""><i class="fas fa-fw fa-caret-right"></i>Invite Unlimited Guests/Students</th>
                  <td style="text-align: center"> </td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                </tr>             
                <tr>
                  <th scope="row" title=""><i class="fas fa-fw fa-caret-right"></i>Private Jobs: Create Invite-only spaces for your team.</th>
                  <td style="text-align: center"> </td>
                  <td style="text-align: center"> </td>
                  <td style="text-align: center"> </td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                </tr>                          

                <!-- DFM Check -->
                <tr>
                  <th scope="row" class="text-md-left text-primary bg-light">PCB ANALYSIS (ASSEMBLY / MANUFACTURING)</th>
                  <td class="text-md-left text-primary bg-light"> </td>
                  <td class="text-md-left text-primary bg-light"> </td>
                  <td class="text-md-left text-primary bg-light"> </td>
                  <td class="text-md-left text-primary bg-light"> </td>
                </tr>

                <tr>
                  <th scope="row" title=""><i class="fas fa-fw fa-caret-right"></i>Organize all violations in the DFM Results Browser tree</th>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                </tr>  
                <tr>
                  <th scope="row" title="Pinpoint DFM Results and describe violations"><i class="fas fa-fw fa-caret-right"></i>Pinpoint DFM Results and describe violations</th>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                </tr>                
                <tr>
                  <th scope="row" title=""><i class="fas fa-fw fa-caret-right"></i>Maximum DFM violations shown per Rule</th>
                  <td style="text-align: center">15</td>
                  <td style="text-align: center">50</td>
                  <td style="text-align: center">Unlimited</td>
                  <td style="text-align: center">Unlimited</td>
                </tr>    
                <tr>
                  <th scope="row" title="Edit/Save any DFM Rule values."><i class="fas fa-fw fa-caret-right"></i>DFM Rules Editor</th>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                </tr>                                         
                <tr>
                  <th scope="row" title=""><i class="fas fa-fw fa-caret-right"></i>Standard DFM Checks (50+ Most Common)</th>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                </tr>     
                <tr>
                  <th scope="row" title=""><i class="fas fa-fw fa-caret-right"></i>Advanced DFM Checks</th>
                  <td style="text-align: center"> </td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                </tr> 
                <tr>
                  <th scope="row" title=""><i class="fas fa-fw fa-caret-right"></i>SMT Stencil DFM Checks</th>
                  <td style="text-align: center"> </td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                </tr>  
                <tr>
                  <th scope="row" title="Perform basic DFA/Assembly Checks"><i class="fas fa-fw fa-caret-right"></i>Assembly DFM Checks</th>
                  <td style="text-align: center"> </td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                </tr>                                                                                   
                <tr>
                  <th scope="row" title="Verify Electrical Connectivity"><i class="fas fa-fw fa-caret-right"></i>Netlist Comparison: Find Shorts and Open Nets</th>
                  <td style="text-align: center"> </td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                </tr>     
                <tr>
                  <th scope="row" title=""><i class="fas fa-fw fa-caret-right"></i>Pinpoint any Open or Shorted Nets</th>
                  <td style="text-align: center"> </td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                </tr>                                                 
                <tr>
                  <th scope="row" title="Import, manage and share any DFM Rule values."><i class="fas fa-fw fa-caret-right"></i>Import DFM Rules</th>
                  <td style="text-align: center"> </td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                </tr>    
                <tr>
                  <th scope="row" title=""><i class="fas fa-fw fa-caret-right"></i>Run Multiple DFM Checks (on same Job)</th>
                  <td style="text-align: center"> </td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                </tr>   
                <tr>
                  <th scope="row" title=""><i class="fas fa-fw fa-caret-right"></i>Shared Team DFM Rules</th>
                  <td style="text-align: center"> </td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                </tr>  
                <tr>
                  <th scope="row" title=""><i class="fas fa-fw fa-caret-right"></i>Compare PCB Designs (Find all differences)</th>
                  <td style="text-align: center"> </td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                </tr>            

                <!-- Admin / Security -->
                <tr>
                  <th scope="row" class="text-md-left text-primary bg-light">ADMIN / SECURITY</th>
                  <td class="text-md-left text-primary bg-light"> </td>
                  <td class="text-md-left text-primary bg-light"> </td>
                  <td class="text-md-left text-primary bg-light"> </td>
                  <td class="text-md-left text-primary bg-light"> </td>
                </tr>

                <tr>
                  <th scope="row" title=""><i class="fas fa-fw fa-caret-right"></i>URL link access controls</th>
                  <td style="text-align: center"> </td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                </tr>  
                <tr>
                  <th scope="row" title=""><i class="fas fa-fw fa-caret-right"></i>Guest access controls</th>
                  <td style="text-align: center"> </td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                </tr>   
                <tr>
                  <th scope="row" title=""><i class="fas fa-fw fa-caret-right"></i>Password protection</th>
                  <td style="text-align: center"> </td>
                  <td style="text-align: center"> </td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                </tr>  
                <tr>
                  <th scope="row" title=""><i class="fas fa-fw fa-caret-right"></i>Centralized administration</th>
                  <td style="text-align: center"> </td>
                  <td style="text-align: center"> </td>
                  <td style="text-align: center"> </td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                </tr>   
                <tr>
                  <th scope="row" title=""><i class="fas fa-fw fa-caret-right"></i>Centralized content management</th>
                  <td style="text-align: center"> </td>
                  <td style="text-align: center"> </td>
                  <td style="text-align: center"> </td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                </tr>  
                <tr>
                  <th scope="row" title=""><i class="fas fa-fw fa-caret-right"></i>Team creation controls</th>
                  <td style="text-align: center"> </td>
                  <td style="text-align: center"> </td>
                  <td style="text-align: center"> </td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                </tr>  


                <!-- Fabrication Tools -->
                <tr>
                  <th scope="row" class="text-md-left text-primary bg-light">FABRICATION TOOLS</th>
                  <td class="text-md-left text-primary bg-light"> </td>
                  <td class="text-md-left text-primary bg-light"> </td>
                  <td class="text-md-left text-primary bg-light"> </td>
                  <td class="text-md-left text-primary bg-light"> </td>
                </tr>
                <tr>
                  <th scope="row" title="Extract PCB fabrication and assembly data used for RFQ/Sales purposes"><i class="fas fa-fw fa-caret-right"></i>Extract PCB fabrication/assembly RFQ data</th>
                  <td style="text-align: center">Limited</td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                </tr>                                         
                <tr>
                  <th scope="row" title=""><i class="fas fa-fw fa-caret-right"></i>Add Teardrops, Copper Pour, Silkscreen Optimizer</th>
                  <td style="text-align: center"> </td>
                  <td style="text-align: center"> </td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                </tr>
                <tr>
                  <th scope="row" title=""><i class="fas fa-fw fa-caret-right"></i>Etch Compensation, Pin Hole Elimination, Remove Pads</th>
                  <td style="text-align: center"> </td>
                  <td style="text-align: center"> </td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                </tr>
                <tr>
                  <th scope="row" title=""><i class="fas fa-fw fa-caret-right"></i>Generate Missing Layers: Paste, Drill, Mask</th>
                  <td style="text-align: center"> </td>
                  <td style="text-align: center"> </td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                </tr>
                <tr>
                  <th scope="row" title=""><i class="fas fa-fw fa-caret-right"></i>Advanced SMT Stencil (Homebase, C-Pad, Split/Scale pads, Epoxy bars and more)</th>
                  <td style="text-align: center"> </td>
                  <td style="text-align: center"> </td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                </tr>            
                <tr>
                  <th scope="row" title=""><i class="fas fa-fw fa-caret-right"></i>PCB Panelization (plus adds Fiducials, Tooling Holes, Venting, Labels, Coupons)</th>
                  <td style="text-align: center"> </td>
                  <td style="text-align: center"> </td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                </tr>


                <!-- API -->
                <tr>
                  <th scope="row" class="text-md-left text-primary bg-light">REST API</th>
                  <td class="text-md-left text-primary bg-light"> </td>
                  <td class="text-md-left text-primary bg-light"> </td>
                  <td class="text-md-left text-primary bg-light"> </td>
                  <td class="text-md-left text-primary bg-light"> </td>
                </tr>
                <tr>
                  <th scope="row" title=""><i class="fas fa-fw fa-caret-right"></i>API Sandbox: Free Test Files</th>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                </tr>               
                <tr>
                  <th scope="row" title=""><i class="fas fa-fw fa-caret-right"></i>API Webhooks (HTTP Endpoints)</th>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                </tr>            
                <tr>
                  <th scope="row" title=""><i class="fas fa-fw fa-caret-right"></i>Generate Secure API Token Keys</th>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                </tr>   
                                  
                <tr>
                  <th scope="row" title=""><i class="fas fa-fw fa-caret-right"></i>API Flat rate: **</th>
              <td style="text-align: center">Sandbox Files Only</td>
              <td style="text-align: center">$0.03 /API call</td>
              <td v-show="true" style="text-align: center">$0.03 /API call</td>
              <td style="text-align: center">Unlimited API Calls </td>
                </tr>

                <!-- SCALE AND ADVANCED SECURITY -->
                <tr>
                  <th scope="row" class="text-md-left text-primary bg-light">SCALE AND ADVANCED SECURITY</th>
                  <td class="text-md-left text-primary bg-light"> </td>
                  <td class="text-md-left text-primary bg-light"> </td>
                  <td class="text-md-left text-primary bg-light"> </td>
                  <td class="text-md-left text-primary bg-light"> </td>
                </tr>
                <tr>
                  <th scope="row" title="Unlimited API calls at a cost-effective monthly rate"><i class="fas fa-fw fa-caret-right"></i>Unlimited API calls**</th>
                  <td style="text-align: center"> </td>
                  <td style="text-align: center"> </td>
                  <td style="text-align: center"> </td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                </tr>

                <tr>
                  <th scope="row" title="AWS GovCloud US hosting for maximum Security and Compliance (ITAR, ISO, etc)"><i class="fas fa-fw fa-caret-right"></i>AWS GovCloud (US) hosting</th>
                  <td style="text-align: center"> </td>
                  <td style="text-align: center"> </td>
                  <td style="text-align: center"> </td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                </tr>   

                <tr>
                  <th scope="row" title="In-House hosting (server-based). Perfect for strict data security requirements and control"><i class="fas fa-fw fa-caret-right"></i>In-House hosting (Server-based)</th>
                  <td style="text-align: center"> </td>
                  <td style="text-align: center"> </td>
                  <td style="text-align: center"> </td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                </tr>


                <!-- Support -->
                <tr>
                  <th scope="row" class="text-md-left text-primary bg-light">SUPPORT AND SERVICES</th>
                  <td class="text-md-left text-primary bg-light"> </td>
                  <td class="text-md-left text-primary bg-light"> </td>
                  <td class="text-md-left text-primary bg-light"> </td>
                  <td class="text-md-left text-primary bg-light"> </td>
                </tr>
                <tr>
                  <th scope="row" title=""><i class="fas fa-fw fa-caret-right"></i>Help Center</th>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                </tr>

                <tr>
                  <th scope="row" title=""><i class="fas fa-fw fa-caret-right"></i>Technical Support and Assistance</th>
                  <td style="text-align: center"> </td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                  <td style="text-align: center"><i class="fas fa-fw fa-check text-success"></i></td>
                </tr>   

                <tr>
                  <th scope="row" title=""><i class="fas fa-fw fa-caret-right"></i>Software Developmment Services</th>
                  <td style="text-align: center"><router-link tag="a" :to="{name:'Contact'}">Contact us</router-link> </td>
                  <td style="text-align: center"><router-link tag="a" :to="{name:'Contact'}">Contact us</router-link> </td>
                  <td style="text-align: center"><router-link tag="a" :to="{name:'Contact'}">Contact us</router-link> </td>
                  <td style="text-align: center"><router-link tag="a" :to="{name:'Contact'}">Contact us</router-link> </td>
                </tr>

              <!-- End Table -->
              </tbody>
          </table>

          </div>
        </div>

        <!-- FAQ -->
        <h3 class="mt-3 text-center">FAQ (Frequently Asked Questions?)</h3>

        <div class="row mb-1" style="color:#6c757d">
          <div class="col">
            <h5 class="text-info">How is my PCB data protected and stored?</h5>
            <p>To keep your data secure, PCB Preflight encrypts stored data using 256-bit AES and hosted on AWS facilities (US-based).</p>
          </div>
        </div>

        <div class="row mb-1" style="color:#6c757d">
          <div class="col">
            <h5 class="text-info">Do you have a discount for universities or non-profits?</h5>
            <p>Everyone is welcome and encouraged to use <b>PCB Preflight: FREE Starter plan</b>, however we do not have any discounts for non-profits. </p>
          </div>
        </div>

        <hr class="my-3"/>
        <div class="row" style="color:#6c757d">
          <div class="col">
            <h5 class="text-info">*Starter Limitations (Free plan only):</h5>
            <p><b>Job Count (3 Max):</b>  A maximum of 3 Jobs may be created and stored (at one time).  If you require additional Jobs, you may either delete existing Jobs (to less than 3), or upgrade to a paid Plan.</p>
            <p><b>Collaborate with other PCB Preflight member(s):</b>  Collaborate with a maximum of 1 person (at a time).  All paid plans have unlimited collaborations plus invite non-members. </p>
        <p><b>Free API Sandbox access:</b>  Easily test and evaluate the PCB Preflight API on all Sandbox files.  Perform up to Level 2 API Matrix Features </p>
          </div>
        </div>

        <hr class="my-3"/>
        <div class="row" style="color:#6c757d">
          <div class="col">
            <h5 class="text-info">** API Usage and Pricing:</h5>
            <p><b class="text-info">Flat rate: $0.03 per API call:</b>  A standard flat rate of $0.03 per API call will be charged.</p>
            <p><b>What is an API call?</b> Each API call is a request to the PCBPreflight.com server to perform a service or task.  Each request to the PCB Preflight server consumes a minimum of one API Call.  Depending on your application usage - multiple API calls may be made (i.e callbacks, etc).  You can check your API dashboard and review the API call usage.</p>
            <p><b>How can I guarantee my API usage never runs out?</b> Set the Auto-refill option ON (in your Billing options).  Each month your account will be automatically charged a flat rate of $0.03 per API call.</p>        
            <p><b>High Volume usage? </b>  <router-link tag="a" :to="{name:'Contact'}">Contact us</router-link> about the Enterprise Plan with Unlimited API calls at a cost-effective monthly rate. </p>      
        <p><b>PCB Preflight - API Sandbox </b>  A controlled environment allowing developers to freely test and evaluate the PCB Preflight API results (without incurring any API costs).</p>      
          </div>
        </div>

        <hr class="my-3"/>
        <div class="row" style="color:#6c757d">
          <div class="col">
            <h5 class="text-info">*** AWS GovCloud <b>Additional Cost Option</b></h5>
            <p>AWS GovCloud (US) is the leading regulated industry cloud solution that technology leaders have trusted to manage sensitive data and controlled unclassified information (CUI).  More cost-effective and easier to manage than in-house hosting.<br/>
            Additional costs include: (1) Initial Setup $10k USD (2) Monthly rate $1k+ USD <br/>Note: AWS GovCloud incurs a higher monthly rate than typical hosting.</p>
          </div>
        </div>

        <hr class="my-3"/>
        <div class="row" style="color:#6c757d">
          <div class="col">
            <h5 class="text-info">**** In-House hosting (server-based) <b>Additional Cost Option</b></h5>
            <p>In-house hosting is perfect for organizations that require strict data security and control.<br/>
            Additional costs include: (1) Initial Setup $30k USD (2) Monthly rate $2k+ USD</p>
          </div>
        </div>

        <hr class="my-3"/>
        <div class="row" style="color:#6c757d">
          <div class="col">
            <h5 class="text-info">Further Questions?</h5>
            <p>Do you have any additional questions? Are you a large volume customer who would like to negotiate an individual agreement? Feel free to <a href="mailto:support@pcbpreflight.com">contact</a> us directly and we'll be happy to assist you further.</p>
          </div>
        </div>
        <!-- PLANS -->
      </div>
      
    </div>

    <!-- Footer -->
    <page-footer></page-footer>
    <!-- Footer -->

  </main>
</template>

<style>
.subtitle {
  line-height:1.5em;
  color: #6c757d;
}
.card-pricing {
  z-index: 1;
  border: 2px solid rgba(0,0,0,0.1);
  border-radius: 16px;
}
.card-pricing.popular {
  border: 2px solid #007bff;
}
.card-pricing .list-unstyled li {
  padding: .4rem 0;
  color: #6c757d;
}
</style>


<script>
  import PageHeader from '../components/PageHeader.vue';
  import PageFooter from '../components/PageFooter.vue';
  import Common from '../mixins/Common'
  import VueSlider from 'vue-slider-component'
  import 'vue-slider-component/theme/default.css'

  export default {

    mixins: [Common],

    components: {
      PageHeader,
      PageFooter,
      VueSlider
    },

    data() {
      return {
        money: new Intl.NumberFormat('en-US',{style:'currency',currency:'USD'}),
        package_mins: 100,
        package_cost: 0.30
      }
    },

    mounted() {
      app = this;
      document.title = 'PCBPreflight Pricing';
    },

    methods: {
    }

  }
</script>
