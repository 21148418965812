<template>
  <main role="main">

    <div style="flex:1 1 auto">

      <!-- Navigation -->
      <page-header nav="0"></page-header>
      <!-- Navigation -->

      <!-- Masthead -->
      <header class="masthead text-white text-center" style="position:relative">
        <div style="position:absolute;right:20px;bottom:-22px;color:#ffffff;opacity:0.5">
          <img src="/images/landing/robot-happy.svg" width="200" height="200"/>
        </div>
        <div class="overlay"></div>
        <div class="container">
          <div class="row">
            <div class="col-xl-10 mx-auto">
              <h1>Free Online PCB Analysis<br/>— before Manufacturing</h1> 
              <h5>PCB Preflight performs free analysis to ensure flawless Manufacturing and Assembly.</h5>
              <p><i>Online DFM/DFA and Collaboration.  Easy Automation using REST API.</i></p>
            </div>
          </div>
        </div>
      </header>

      <!-- DESCRIPTION -->
      <section class="showcase py-1">
        <div class="container py-1">
          <div class="row">

            <div class="col-12 col-lg-7">
              <b-img src="/images/landing/pcbpreflight_dashboard.png" fluid-grow class="px-5" alt="PCB Preflight - Job Dashboard" title="PCB Preflight - Job Dashboard"></b-img>
              <h5 class="mt-3">What's Preflight?</h5>
              <p class="lead mb-3"><a href="https://www.merriam-webster.com/dictionary/preflight" target="_blank"><b>Preflight</b></a> is defined as <span class="text-caption bg-light rounded-pill px-1 py-1">the final inspection performed on an aircraft <b class="text-secondary">before every takeoff</b>.</span>  In the digital Printing Market <span style="font-size:smaller;" class="text-muted">($400B)</span>, <a href="https://en.wikipedia.org/wiki/Pre-flight_(printing)" target="_blank"><b>Preflight</b></a> means <span class="text-caption bg-light rounded-pill px-1 py-1">to identify and correct any problems <b class="text-secondary">before design handoff</b>.</span></p>
              <h5 class="mt-4">What is PCB Preflight?</h5>
              <p class="lead mb-3"><a href="https://www.pcbpreflight.com/landing/signup" target="_blank"><b>PCB Preflight</b></a> is the <b class="text-secondary">final sanity check</b> performed on a design to identify and correct any PCB manufacturing issues <b class="text-secondary">before handoff</b>.</p> 
              <h5 class="mt-4">Why use PCB Preflight?</h5>
              <p class="lead">Using <a href="https://www.pcbpreflight.com/landing/signup" target="_blank"><b>PCB Preflight</b></a> <b class="text-secondary mr-1"> before every design handoff</b> helps designers and manufacturers build great boards, together — by ensuring the Manufacturability of all PCB prototypes and NPIs!</p>
              <router-link tag="button" class="btn btn-lg btn-primary mt-4" :to="{name:'Signup'}">Get started for free</router-link>
              <br/>
              <router-link :to="{name:'Login'}" tag="button" class="btn btn-lg btn-secondary mt-3">Already a member? Click here.</router-link>
            </div>

            <div class="col-12 col-lg-5 mt-2 mt-lg-0">
              <div class="card card-body text-dark d-flex justify-content-center align-items-center text-center" style="font-size:1.15em;background-color:#eaeaff;position:relative">
                <div class="px-1 pt-0 pb-2 rounded" style="z-index:10">
                  <p class="p-1 m-0"><i class="fas fa-fw fa-check text-success mr-1"></i><b class="text-success">FREE</b> PCB Manufacturing Checks</p>
                  <p class="p-1 m-0"><i class="fas fa-fw fa-check text-success mr-1"></i><b class="text-success">FREE</b> Design Share and Collaboration</p>
                  <p class="p-1 m-0"><i class="fas fa-fw fa-check text-success mr-1"></i><b class="text-success">FREE</b> BOM and STACKUP viewer</p>
                  <p class="p-1 m-0"><i class="fas fa-fw fa-check text-success mr-1"></i><b class="text-success">API</b> Online PCB Procurement</p>
                  <hr/>
                  <p class="p-1 m-0">Compare PCB Designs</p>
                  <p class="p-1 m-0">PCB Quote/Sales Tools</p>
                  <p class="p-1 m-0">PCB Panelization + PreCAM</p>                  
                  <p class="p-1 m-0">Advanced, SMT Stencil Checks</p>
                  <p class="p-1 m-0">Import and Edit DFM Rules</p>
                  <p class="p-1 m-0">Find Shorts and Open Nets</p>
                  <p class="p-1 m-0">Secure (256-bit AES Encryption)</p>
                  <hr/>
                  <p class="p-1 m-0"><b>Available on all devices!</b></p>
                  <p class="p-1 m-0">PC: Windows, Linux, MAC</p>
                  <p class="p-1 m-0">Mobile: iOS, Android</p>
                  <p class="mt-2"><img src="/images/landing/visualize_phone.png" width="110" height="150" class="border border-1 border-primary"></p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>
      <!-- DESCRIPTION -->


      <!-- Icons Grid -->
      <section class="features-icons bg-light text-center d-none d-lg-block">
        <div class="container">
          <div class="row">
            <div class="col-4">
              <div class="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
                <div class="features-icons-icon d-flex">
                  <i class="icon-rocket m-auto text-primary"></i>
                </div>
                <h3>Fast & Efficient</h3>
                <p class="lead mb-0">Online cloud processing saves money and is more efficient.</p>
              </div>
            </div>
            <div class="col-4">
              <div class="features-icons-item mx-auto mb-0 mb-lg-3">
                <div class="features-icons-icon d-flex">
                  <i class="icon-organization m-auto text-primary"></i>
                </div>
                <h3>Upload your Designs</h3>
                <p class="lead mb-0">Full support for IPC2581, ODB++, Gerber (274X/X2/X3), NC, DXF and more.</p>
              </div>
            </div>
            <div class="col-4">
              <div class="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
                <div class="features-icons-icon d-flex">
                  <i class="icon-badge m-auto text-primary"></i>
                </div>
                <h3>DFM Check Verification</h3>
                <p class="lead mb-0">Ensure PCB's are built correctly the first time!</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- Icons Grid -->

      <!-- Call to Action -->
      <section class="call-to-action text-white text-center">
        <div class="overlay"></div>
        <div class="container">
          <div class="row">
            <div class="col-xl-9 mx-auto">
              <h2 class="mb-4">Ready to Get started?</h2>
            </div>
            <div class="col-12">
              <router-link tag="button" class="btn btn-lg btn-primary mt-1" :to="{name:'Signup'}">Get started for Free!</router-link>
            </div>
          </div>
        </div>
      </section>
      <!-- Call to Action -->

    </div>

    <!-- Footer -->
    <page-footer></page-footer>
    <!-- Footer -->

  </main>
</template>


<script>
  import PageHeader from '../components/PageHeader.vue';
  import PageFooter from '../components/PageFooter.vue';
  import Common from '../mixins/Common'

  export default {

    mixins: [Common],

    components: {
      PageHeader,
      PageFooter
    },

    mounted() {
      app = this;
      document.title = "PCB Preflight";
    },

  }
</script>
